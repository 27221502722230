<template>
  <section class="container">
    <article class="pt-3">
      <header class="col titulo h5-responsive text-center text-lg-left pl-4 pt-3 mb-3">
        Filtros de búsqueda
      </header>
      <form class="filtros" @submit.prevent>
        <div class="px-0">
          <FiltrosTipo1
            :zonas="zonasFiltro"
            :ven="vendedoresFiltro"
            :mes="true"
            :desdeHasta="true"
            :mostrar-btn-restablecer="true"
            :restablecer-filtrado="restablecer"
            :responsive="{
              mes: 'col-12 col-sm-4',
              desde: 'col-12 col-sm-4 col-lg',
              hasta: 'col-12 col-sm-4 col-lg'
            }"
            facturas
            nombre
            @mesAFiltrar="fechaFac = $event; aplicarFiltros()"
            @desdeAFiltrar="fechaDel = $event; aplicarFiltros()"
            @hastaAFiltrar="fechaHasta = $event; aplicarFiltros()"
            @zonasAFiltrar="aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
            @vendedorAFiltrar="vendedorAFiltrar=$event; aplicarFiltros(); sucCam = []"
            @estatusFacturaAFiltrar="estatusFiltrar=$event; aplicarFiltros()"
            @nombreAFiltrar="nombreAFiltrar=$event; aplicarFiltros()"
            @eliminarFiltros="RestablecerFiltros"
          />
        </div>
      </form>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <ul
        v-else-if="Object.keys(facturas).length"
        class="table t-facturas"
      >
        <li class="encabezado sticky">
          <div class="row align-items-center mx-0">
            <div class="col-12 col-md px-0 text-center text-sm-left">
              <header class="h4-responsive">Facturas</header>
              <p class="descripcion text-capitalize">En lista: {{Object.keys(facturas).length}}</p>
            </div>
          </div>
          <div class="thead claro">
            <div class="col-12 col-md">
              Cliente - Sucursal
              <span class="small-block">
                N° de factura
              </span>
            </div>
            <div class="col-12 col-md-2">
              Fecha de factura
              <span class="small-block">
                Fecha de vencimiento
              </span>
            </div>
            <div class="col-12 col-md-2">
              Estatus de factura
              <span class="small-block">
                Días
              </span>
            </div>
            <div class="col-12 col-md-2 text-right">
              Monto
            </div>
            <div class="col-12 col-md-2 text-center">Acciones</div>
          </div>
        </li>
        <li class="contenido" v-for="fac in facturas" :key="fac.id_fac">
          <div class="col col-md" data-columna="Cliente - Sucursal">
            {{fac.nom_emp}} - {{fac.nom_suc}}
            <span class="small-block">
              {{ fac.num_fac }}
            </span>
          </div>
          <div class="col col-md-2" data-columna="Fecha de factura">
            {{ fac.fec_fac }}
            <span class="small-block">
              {{ fac.fec_ven_fac }}
            </span>
          </div>
          <div class="col col-md-2" data-columna="Estatus de factura / Días">
            <p :class="[asignarColorEstado(fac.est_fac), 'text-capitalize font-weight-bold']">
              {{  fac.est_fac }}
              <small
                v-if="fac.fec_ven_fac && fac.est_fac !== 'Solvente'"
                :class="[diasEstatusFac(fac.fec_ven_fac).color, 'font-weight-bold d-block']"
              >
                {{diasEstatusFac(fac.fec_ven_fac).dias}} días
              </small>
            </p>
          </div>
          <div class="col col-md-2 text-right" data-columna="Monto">
            <p>
              {{ fac.mon_fac }}
            </p>
          </div>
          <div class="col col-md-2 text-center" data-columna="Acciones">
            <mdb-btn
              flat
              dark-waves
              icon="pen"
              size="sm"
              class="px-1 mx-0"
              @click="mostrarModalEditarFactura= true, facturaSeleccionada = fac"
            >
              Editar
            </mdb-btn>
          </div>
        </li>
      </ul>
      <div
        v-else-if="facturas && !Object.keys(facturas).length && !cargando"
        class="mensaje-no-items py-5"
      >
        <span class="fa-stack icono position-relative ml-n4 mb-4">
          <font-awesome-icon
            icon="search"
            size="5x"
            class="fa-stack-2x"
          />
          <font-awesome-icon
            icon="times"
            size="2x"
            class="fa-stack-1x"
            style="position: absolute;top: -10px;left: 14px;"
          />
        </span>
        <p class="texto text-center">
          No se encontraron facturas
          <span class="d-block">durante la búsqueda</span>
        </p>
      </div>
    </article>
    <ModalEditarFactura
      :mostrarModalEditarFactura="mostrarModalEditarFactura"
      :facturaSeleccionada="facturaSeleccionada"
      @cerrar="mostrarModalEditarFactura = $event"
      @actualizar="aplicarFiltros();mostrarModalEditarFactura = false"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
// @ is an alias to /src
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado, formatearFechaSinHora } from '@/funciones/funciones.js'
import ModalEditarFactura from '@/components/Modals/ModalEditarFactura.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'
import { mdbBtn } from 'mdbvue'

export default {
  name: 'FacturasView',
  components: {
    FiltrosTipo1,
    AlertaMensaje,
    LoaderKel,
    ModalEditarFactura,
    mdbBtn
  },
  data () {
    return {
      asignarColorEstado,
      formatearFechaSinHora,
      fechaActual: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + (new Date().getDate())).substr(-2),
      facturas: [],
      mostrarModalEditarFactura: false,
      facturaSeleccionada: {},
      fechaFac: '',
      fechaDel: '',
      fechaHasta: '',
      estatusFiltrar: '',
      nombreAFiltrar: '',
      zonasFiltro: [],
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: '',
      vendedoresFiltro: [],
      vendedorAFiltrar: [],
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      cargando: false,
      restablecer: false
    }
  },
  watch: {
    fechaFac () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.aplicarFiltros()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.aplicarFiltros()
    }
  },
  methods: {
    // Debe traer las zonas, los vendedores y los tipos de clientes.
    // También los primeros 50 clientes por orden alfabético, los demás los trae al hacer scroll (si
    // no se ha seleccionado otro filtro)
    cargaInicial () {
      this.resultados = {}
      apiPost({ s: 'filtros' }, '')
        .then((res) => {
          this.vendedoresFiltro = res.data.ven
          this.zonasFiltro = res.data.zon
        })
    },
    aplicarFiltros () {
      this.cargando = true
      let data = this.fechaFac ? 'y=' + this.fechaFac.split('-')[0] + '&m=' + this.fechaFac.split('-')[1] : ''
      data += this.fechaHasta && this.fechaDel && this.fechaHasta.length && this.fechaDel.length ? '&del=' + this.fechaDel + '&al=' + this.fechaHasta : ''
      data += Object.keys(this.estatusFiltrar).length ? '&estatus=' + this.estatusFiltrar : ''
      data += this.nombreAFiltrar.length ? '&nombre=' + this.nombreAFiltrar : ''
      data += this.vendedorAFiltrar.length ? '&vendedores=' + this.vendedorAFiltrar : ''
      data += this.estadosAFiltrar.length ? '&estados=' + this.estadosAFiltrar : ''
      data += this.municipiosAFiltrar.length ? '&municipios=' + this.municipiosAFiltrar : ''
      data += this.parroquiasAFiltrar.length ? '&parroquias=' + this.parroquiasAFiltrar : ''
      apiPost({ s: 'facturasMv' }, data)
        .then((res) => {
          this.cargando = false
          this.facturas = res.data
        })
    },
    aplicarFiltrosZonas (estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []

      estadosFil.forEach(e => {
        this.estadosAFiltrar.push(e.est_zon)
      })
      municipiosFil.forEach(m => {
        this.municipiosAFiltrar.push(m.ciu_zon)
      })
      parroquiasFil.forEach(p => {
        this.parroquiasAFiltrar.push(p.sec_zon)
      })

      this.aplicarFiltros()
    },
    eliminarFiltrosSeleccionados () {
      this.nombreAFiltrar = ''
      this.fechaAFiltrar = ''
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.estatusFacturaAFiltrar = []
      this.condiPagoAFiltrar = []
      this.clientes = {}
      this.totalSucursales = 0
    },
    diasEstatusFac (ven) {
      let dif = 0
      let color = ''
      let dias = ''
      const venFac = formatearFechaSinHora(ven)
      const actual = formatearFechaSinHora(this.fechaActual)
      const aFecha1 = venFac.split('-')
      const aFecha2 = actual.split('-')
      const fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0])
      const fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0])
      dif = fFecha2 - fFecha1
      const total = Math.floor(dif / (1000 * 60 * 60 * 24))
      if (total < 0) {
        dias = 'Faltan ' + Math.abs(total)
        color = 'texto-exitoso'
      } else {
        dias = 'Pasaron ' + Math.abs(total)
        color = 'texto-error'
      }
      return { dias, color }
    },
    RestablecerFiltros () {
      this.restablecer = true
      this.fechaDel = ''
      this.fechaHasta = ''
      this.nombreAFiltrar = ''
      this.estatusFiltrar = ''
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.vendedorAFiltrar = []
      this.fechaFac = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
      this.aplicarFiltros()
      setTimeout(() => {
        this.restablecer = false
      }, 500)
    }
  },
  computed: {
    filtrosActivos () {
      return (this.estadosAFiltrar.length || this.municipiosAFiltrar.length || this.parroquiasAFiltrar.length ||
      this.nombreAFiltrar.length || this.fechaAFiltrar.length)
    }
  },
  mounted () {
    this.fechaFac = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
    this.cargaInicial()
  }
}
</script>
<style lang="scss" scoped>
.filtros {
  margin-bottom: 1rem;
  @media screen and (min-width: 768px) {
    margin: auto .5rem 1.5rem;
  }
}
.table.t-facturas {
  margin: 2.5rem auto 6rem;

  .contenido > [class*=col] {line-height: 1.3}

  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ('768px');
}
</style>
