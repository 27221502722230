<template>
  <article class="contenedor-hijo">
    <div class="container">
      <div class="row justify-content-center justify-content-md-between mb-3 ml-2 ml-sm-0">
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="arrow-left"
            type="button"
            title="Regresar"
            @click="$router.go(-1)"
          >
            Regresar
          </mdb-btn>
        </div>
        <div
          v-if="pago && pago.length && pago[0].est_aco !== 'Auditado'  && pago[0].est_aco !== 'Confirmado'"
          class="col-auto px-0"
        >
          <mdb-btn
            flat
            dark-waves
            icon="sync"
            type="button"
            title="Cambiar estatus"
            @click="mostrarModalCambiarEstatus= !mostrarModalCambiarEstatus"
          >
            Cambiar estatus
          </mdb-btn>
        </div>
        <!-- En vendedor debe ser igual a Notificado. -->
        <div
          v-if="pago && pago.length && pago[0].est_aco === 'Notificado'"
          class="col-auto px-0"
        >
          <mdb-btn
            flat
            dark-waves
            icon="ban"
            type="button"
            class="mx-0 px-4 texto-primario"
            title="Eliminar pago"
            @click="modalConfirmacionEliminar = !modalConfirmacionEliminar"
          >
            Eliminar pago
          </mdb-btn>
        </div>
        <!-- En vendedor debe ser distinto a Notificado o Rechazado. -->
        <div
          v-if="pago && pago.length && pago[0].est_aco !== 'Auditado'"
          class="col-auto px-0"
        >
          <mdb-btn
            flat
            dark-waves
            icon="pen"
            type="button"
            title="Editar pago"
            @click="mostrarModalEditarPago= !mostrarModalEditarPago"
          >
            Editar pago
          </mdb-btn>
        </div>
      </div>
      <li
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </li>
      <div v-else>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles del pago</header>
          <div
            class="row mx-0 mt-2"
            v-if="pago && pago.length"
          >
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Nombre del vendedor</span>
              <p class="text-capitalize">{{pago[0].nom_per}} {{pago[0].ape_per}}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Estatus del pago</span>
              <p
                v-if="pago && pago[0].est_aco"
                :class="[asignarColorEstado(pago[0].est_aco), 'font-weight-bold'] "
              >
                {{pago && pago.length && pago[0].est_aco.slice(0,1).toUpperCase() + pago[0].est_aco.slice(1) }}
              </p>
              <p
                v-else
                class="text-muted font-italic user-select-none"
              >
                Sin especificar
              </p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Número de pago</span>
              <p class="text-capitalize">{{ this.$route.params.id }}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Banco</span>
              <p :class="['text-capitalize', {'text-muted font-italic user-select-none': !(pago && pago.length && pago[0] && pago[0].nom_cba)}]">
                {{ pago && pago.length && pago[0] && pago[0].nom_cba ? pago[0].nom_cba : 'Sin banco especificado' }}
              </p>
            </div>
            <div class="col-12 col-sm-8 col-xl-6 pr-sm-1">
              <span class="small-block font-titulo">Cliente, Sucursal</span>
              <p>
                <span :class="!(pago && pago.length && pago[0] && pago[0].nom_emp) ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{pago && pago.length && pago[0] && pago[0].nom_emp ? pago[0].nom_emp : 'Cliente no especificado' }},
                </span>
                <span :class="!(pago && pago.length && pago[0] && pago[0].nom_suc) ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ pago && pago.length && pago[0] && pago[0].nom_suc ? pago[0].nom_suc : 'Sucursal no especificada' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Moneda</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].nom_mon }} / {{ pago && pago.length && pago[0] && pago[0].sim_mon }} </p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Método de pago</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].nom_mep }}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Referencia de pago</span>
              <p :class="['text-capitalize',{'text-muted font-italic user-select-none': !(pago && pago.length && pago[0] && pago[0].ref_aco)}]">
                {{ pago && pago.length && pago[0] && pago[0].ref_aco ?  pago[0].ref_aco : 'Sin referencia especificada' }}
              </p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Tasa de cambio</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && convertirNumeroAFixed(pago[0].tas_aco) }}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Monto de pago</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && convertirNumeroAFixed(pago[0].mon_aco) }} {{pago && pago.length && pago[0] && pago[0].sim_mon}}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Indexación del pago</span>
              <p :class="['text-capitalize',{'text-muted font-italic user-select-none': !(pago && pago.length && pago[0] && pago[0].ind_aco)} ]">{{ pago && pago.length && pago[0] && pago[0].ind_aco ? pago[0].ind_aco : 'Sin especificar'}}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Fecha notificado</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].fec_cre_aco ? formatearFechaPago(pago[0].fec_cre_aco) : 'Sin fecha especificada' }}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Fecha recibido</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].fec_rec_aco ? formatearFechaPago(pago[0].fec_rec_aco) : 'Sin fecha especificada' }}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Fecha depositado</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && pago[0].fec_dep_aco ? formatearFechaPago(pago[0].fec_dep_aco) : 'Sin fecha especificada' }}</p>
            </div>
            <div :class="[pago[0].obs_aco.length > 88 ? 'col-12' :'col', 'pr-sm-1']">
              <span class="small-block font-titulo">Observación</span>
              <p :class="{'text-muted font-italic user-select-none': !(pago && pago.length && pago[0] && pago[0].obs_aco)}">
                {{(pago && pago.length && pago[0] && pago[0].obs_aco && pago[0].obs_aco ) || 'Sin especificar'}}
              </p>
            </div>
          </div>
          <div
            v-else
            class="mensaje-no-items pb-3"
          >
            <font-awesome-icon icon="clipboard" size="5x" class="icono" />
            <p class="texto">Sin detalles del pago</p>
          </div>
        </div>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles de la factura</header>
          <div
            class="row mx-0 mt-2"
            v-if="pago[0] && pago[0].id_fac"
          >
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Fecha de factura</span>
              <p :class="['text-capitalize']">
                {{ formatearFechaPago(pago[0].fec_fac) }}
              </p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Fecha de vencimiento</span>
              <p :class="['text-capitalize']">
                {{ formatearFechaPago(pago[0].fec_ven_fac) }}
              </p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Número de factura</span>
              <p :class="['text-capitalize',{'text-muted font-italic user-select-none': !(pago && pago.length && pago[0] && pago[0].num_fac)}]">
                {{ pago[0].num_fac ? pago[0].num_fac : 'Sin número asignado' }}
              </p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Monto de factura</span>
              <p class="text-capitalize">{{ pago && pago.length && pago[0] && convertirNumeroAFixed(pago[0].mon_fac) }}</p>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 pr-sm-1">
              <span class="small-block font-titulo">Estatus de la factura</span>
              <p
                v-if="pago && pago[0].est_fac"
                :class="[asignarColorEstado(pago[0].est_fac), 'font-weight-bold'] "
              >
                {{pago && pago.length && pago[0].est_fac.slice(0,1).toUpperCase() + pago[0].est_fac.slice(1) }}
              </p>
              <p
                v-else
                class="text-muted font-italic user-select-none"
              >
                Sin especificar
              </p>
            </div>
            <div :class="[pago[0].obs_fac.length > 88 ? 'col-12' :'col', 'pr-sm-1']">
              <span class="small-block font-titulo">Observación de la factura</span>
              <p :class="{'text-muted font-italic user-select-none': !(pago && pago.length && pago[0] && pago[0].obs_fac)}">
                {{(pago && pago.length && pago[0] && pago[0].obs_fac && pago[0].obs_fac ) || 'Sin especificar'}}
              </p>
            </div>
          </div>
          <div
            v-else
            class="mensaje-no-items pb-3"
          >
            <font-awesome-icon icon="clipboard" size="5x" class="icono" />
            <p class="texto">Sin detalles de la factura</p>
          </div>
        </div>
      </div>
    </div>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarModalCambiarEstatus"
      @show="estatusPago = pago[0].est_aco"
      @close="estatusPago = ''; mostrarModalCambiarEstatus = false;"
    >
      <mdb-modal-header>
        <mdb-modal-title>Actualizar estatus del pago</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="col-12">
          <div class="md-form md-outline outline-select my-1">
            <select
              v-model="estatusPago"
              id="estatus-select"
              class="custom-select"
            >
              <option class="d-none" value="" disabled>
                Seleccione
              </option>
              <option v-for="(estatus , i) in EstatusPago" :key="i"
                :value="estatus.nombre"
              >
                {{estatus.nombre}}
              </option>
            </select>
            <label
              for="estatus-select"
              :class="estatusPago.length ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
        </div>
        <div class="row justify-content-around">
          <mdb-btn
            type="submit"
            form="form-cliente"
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="[{'fa-spin': botonDeshabilitado}]"
            :disabled="botonDeshabilitado"
            @click="cambiarEstatusPago"
          >
            <span>Actualizar</span>
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <ModalFormularioPagos
      :esEditar="true"
      :pagoAEditar="pago && pago[0]"
      :pagoId="$route.params.id"
      :mostrarModalFormPagos="mostrarModalEditarPago"
      :BancosPago="BancosPago"
      :MetodosPago="MetodosPago"
      :MonedasPago="MonedasPago"
      @alertaMensaje="alertaMensaje = $event"
      @cerrar="mostrarModalEditarPago = false"
      @editarPago="obtenerDetallesPago()"
      @actualizar="$emit('actualizar')"
    />
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      textoBotonConfirmar="Eliminar"
      titulo="¡Esta acción es irreversible!"
      mensaje="Se eliminará el pago y su información del sistema"
      @cerrar="modalConfirmacionEliminar = false;"
      @confirmar="cancelarPago"
    />
  </article>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado, convertirNumeroAFixed } from '@/funciones/funciones.js'
import ModalFormularioPagos from '@/components/Modals/FormularioPagos.vue'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle
} from 'mdbvue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'
import {
  EstatusPago
} from '@/constantes/pagos.js'
export default {
  name: 'PagoView',
  components: {
    mdbBtn,
    ConfirmacionEliminar,
    AlertaMensaje,
    LoaderKel,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    ModalFormularioPagos
  },
  data () {
    return {
      asignarColorEstado,
      convertirNumeroAFixed,
      EstatusPago,
      // Modal cambiar estatus al pago
      estatusPago: '',
      mostrarModalCambiarEstatus: false,
      // Editar pago
      mostrarModalEditarPago: false,
      // Pago
      pago: [],
      notaspago: [],
      productospago: [],
      botonDeshabilitado: false,
      mostrarModalTomarCantidad: false,
      // datos para el formulario de pagos
      BancosPago: [],
      MetodosPago: [],
      MonedasPago: [],
      // Eliminar pago
      modalConfirmacionEliminar: false,
      cargando: false,
      alertaMensaje: {
        contenido: ''
      }
    }
  },
  mounted () {
    this.obtenerDetallesPago()
  },
  computed: {
  },
  methods: {
    async obtenerDetallesPago () {
      this.cargando = true
      if (!this.$route.params.id) {
        this.$router.push('/')
        return
      }
      // Obtener info de cuentas, metodos y bancos
      try {
        const { data } = await apiPost({ s: 'cobrosBase' }, '')
        this.BancosPago = data.cuentas
        this.MetodosPago = data.metodos
        this.MonedasPago = data.monedas
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error al obteniendo los bancos',
          tipo: 'error'
        }
      } finally {
        this.cargando = false
      }
      // obtener detalles del pago
      try {
        const { data } = await apiPost(
          { s: 'cobroCliente' },
          'aco=' + Number(this.$route.params.id)
        )
        if (data && data.cobro) {
          this.pago = data.cobro
          if (this.pago[0] && this.pago[0].est_aco) {
            this.estatusPago = this.pago[0].est_aco
          }
        }
        this.cargando = false
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'No se pudo obtener el pago',
          tipo: 'error'
        }
        this.cargando = false
      }
    },
    formatearFechaPago (fechaPlana) {
      const mess = new Date(fechaPlana + 'Z-4:30')
      const opt = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      const mesLetras = mess.toLocaleDateString('es-VE', opt)
      return mesLetras
    },
    cambiarEstatusPago () {
      this.botonDeshabilitado = true
      const est = JSON.parse(JSON.stringify(this.estatusPago))
      const variablesPost = {
        aco: this.$route.params.id,
        est
      }
      apiPost({ s: 'pagoEditar' }, JSON.stringify(variablesPost))
        .then(({ data }) => {
          if (data && data.r) {
            this.alertaMensaje = {
              contenido: 'El estatus ha sido actualizado correctamente',
              tipo: 'correcto'
            }
            this.pago[0].est_aco = est
            this.mostrarModalCambiarEstatus = false
            this.botonDeshabilitado = false
            this.$emit('actualizar')
          }
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error cambiando el estatus del pago, por favor intenta de nuevo',
            tipo: 'error'
          }
        })
      this.estatusPago = ''
      this.mostrarModalCambiarEstatus = false
      this.botonDeshabilitado = false
    },
    async cancelarPago () {
      this.botonDeshabilitado = true
      apiPost({ s: 'pagoEliminar' }, 'aco=' + this.$route.params.id)
        .then((response) => {
          if (response.data.r) {
            this.alertaMensaje = {
              contenido: 'Pago eliminado correctamente',
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            setTimeout(() => this.$router.go(-1), 2000)
          } else {
            this.alertaMensaje = {
              contenido: 'No se pudo eliminar el pago. Solo puedes eliminar pagos que hayas creado.',
              tipo: 'advertencia'
            }
          }
          this.botonDeshabilitado = false
        }).catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ocurrió un error eliminando el pago.',
            tipo: 'error'
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.table.p-notas {
  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
</style>
