<template>
  <form
    :class="['row filtros mx-0', {'justify-content-sm-center justify-content-lg-start': estadoSeleccionado && !municipioSeleccionado.length}]"
    @submit.prevent
  >
    <!-- Tipos de clientes -->
    <div class="col-12 col-sm-6 col-lg-4" v-if="Object.keys(tipos).length">
      <v-select
        v-model="tiposCliente"
        :searchable="false"
        :options="Object.values(tipos)"
        :getOptionLabel="tipos => tipos.nom_tcl"
        multiple
        class="outline-datalist my-2"
        @input="tiposCliEnvio"
      >
        <template #header>
          <label :class="['datalist-label', {'activo':tiposCliente.includes(tipos.id_tcl)}]">
            Tipos de cliente
          </label>
        </template>
        <template #option="tipos">
          {{tipos.nom_tcl}}
        </template>
      </v-select>
    </div>
    <!-- Vendedores -->
    <div :class="[responsive.vendedor ? responsive.vendedor : 'col-12 col-sm-6 col-lg-4']" v-if="Object.keys(vendedores).length">
      <v-select
        v-model="vendedor"
        :searchable="false"
        :options="Object.values(vendedores)"
        :getOptionLabel="vendedores => vendedores.nom_per"
        multiple
        class="outline-datalist my-2"
        @input="vendedoresEnvio"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': vendedor.includes(vendedores.id_ven)}]">
            Vendedores
          </label>
        </template>
        <template #option="vendedores">
          {{vendedores.nom_per}} {{vendedores.ape_per}}
        </template>
      </v-select>
    </div>
    <!-- Condición de pago -->
    <div class="col-12 col-sm-6 col-lg-4" v-if="conDePago.length">
      <v-select
        v-model="conPago"
        :searchable="false"
        :options="conDePago"
        :getOptionLabel="conDePago => conDePago.nom_cp"
        multiple
        class="outline-datalist my-2"
        @input="conPagoEnvio"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': conPago.includes(conDePago.id_cp)}]">
            Condición de pago
          </label>
        </template>
        <template #option="conDePago">
          {{conDePago.nom_cp}}
        </template>
      </v-select>
    </div>
    <!-- Morosidad -->
    <div class="col-12 col-sm-6 col-lg-4" v-if="facturas">
      <v-select
        v-model="factura"
        :searchable="false"
        :options="EstatusFactura"
        :getOptionLabel="EstatusFactura => EstatusFactura.nombre"
        multiple
        class="outline-datalist my-2"
        @input="facturaEnvio"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': factura.includes(EstatusFactura.nombre)}]">
            Estatus de factura
          </label>
        </template>
        <template #option="EstatusFactura">
          {{EstatusFactura.nombre}}
        </template>
      </v-select>
    </div>
    <!-- Tipo de precio -->
    <div class="col-12 col-sm-6 col-lg-4" v-if="tipPrecio.length">
      <v-select
        v-model="tPre"
        :searchable="false"
        :options="tipPrecio"
        :getOptionLabel="tipPrecio => tipPrecio.nom_tpre"
        multiple
        class="outline-datalist my-2"
        @input="tPreEnvio"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': tPre.includes(tipPrecio.id_tpre)}]">
            Tipo de precio
          </label>
        </template>
        <template #option="tipPrecio">
          {{tipPrecio.nom_tpre}}
        </template>
      </v-select>
    </div>
    <!-- Fecha -->
    <div
      v-if="fecha"
      class="col-12 col-sm-6 col-lg-4"
    >
      <mdbInput
        v-model="fechaAFiltrar"
        type="date"
        label="Fecha"
        class="my-2"
        outline
        @input="$emit('fechaAFiltrar', fechaAFiltrar);"
      />
    </div>
    <!-- Mes -->
    <div v-if="mes" :class="[ responsive.mes ? responsive.mes : 'col-12 col-sm-6 col-md']">
      <mdb-input
        class="my-2"
        v-model="fechaMes"
        type="month"
        label="Mes"
        outline
        @input="$emit('mesAFiltrar', fechaMes);"
      />
    </div>
    <!-- Fecha Desde -->
    <div v-if="desdeHasta" :class="[ responsive.mes ? responsive.desde : 'col-12 col-sm-6 col-md']">
      <mdb-input
        class="my-2"
        v-model="fechaDel"
        type="date"
        label="Desde"
        outline
        @input="$emit('desdeAFiltrar', fechaDel);"
      />
    </div>
    <!-- Fecha Hasta -->
    <div v-if="desdeHasta" :class="[ responsive.mes ? responsive.hasta : 'col-12 col-sm-6 col-md']">
      <mdb-input
        class="my-2"
        v-model="fechaHasta"
        type="date"
        label="Hasta"
        outline
        @input="$emit('hastaAFiltrar', fechaHasta);"
      />
    </div>
    <!-- Nombre -->
    <div
      v-if="nombre"
      class="col-12 col-sm-6 col-lg-4"
    >
      <mdb-input
        v-model="nombreAFiltrar"
        label="Nombre del cliente"
        class="my-2"
        outline
        @input="$emit('nombreAFiltrar', nombreAFiltrar);"
      />
    </div>
    <!-- Estados -->
    <div v-if="Object.keys(estados).length" class="col-12 col-sm-6 col-lg-4">
      <v-select
        v-model="estadosAFiltrar"
        :searchable="false"
        :options="Object.values(estados)"
        :getOptionLabel="estados => estados.est_zon"
        multiple
        class="outline-datalist my-2"
        small-text="Haz clic en el botón > del estado para ver sus municipios"
        @input="municipios={};municipioSeleccionado = [];estadoSeleccionado = [];municipiosAFiltrar=[];parroquiasAFiltrar=[];$emit('zonasAFiltrar', {estados: estadosAFiltrar, municipios: [], parroquias: []});"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': estadosAFiltrar && Object.keys(estadosAFiltrar).length}]">
            Estado(s)
          </label>
        </template>
        <template #option="estados">
          <div class="row-flex">
            <p>{{ estados.est_zon }}</p>
            <mdb-btn
              flat
              dark-waves
              icon="angle-right"
              type="button"
              class="m-0 py-1 px-3"
              :title="`Seleccionar municipio/s del estado ${estados.est_zon}`"
              @click="seleccionarMunicipios(estados)"
            />
          </div>
        </template>
      </v-select>
    </div>
    <!-- Municipios -->
    <div v-if="estadoSeleccionado.length" class="col-12 col-sm-6 col-lg-4"
    >
      <v-select
        v-model="municipiosAFiltrar"
        :searchable="false"
        :options="Object.values(municipios)"
        :getOptionLabel="municipios => municipios.ciu_zon"
        multiple
        class="outline-datalist my-2"
        small-text="Haz clic en el botón > del municipio para ver sus parroquias"
        @input="$emit('zonasAFiltrar', {estados: estadoSeleccionado, municipios: municipiosAFiltrar, parroquias: []}); municipioSeleccionado = []"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': municipiosAFiltrar && Object.values(municipiosAFiltrar).length}]">
            Municipio(s)
          </label>
        </template>
        <template #option="municipios">
          <div class="row-flex">
            <p>{{ municipios.ciu_zon }}</p>
            <mdb-btn
              flat
              dark-waves
              icon="angle-right"
              type="button"
              class="m-0 py-1 px-3"
              :title="`Seleccionar parroquia/s del municipio ${municipios.ciu_zon}`"
              @click="seleccionarParroquias(municipios)"
            />
          </div>
        </template>
      </v-select>
    </div>
    <!-- Parroquias -->
    <div v-if="municipioSeleccionado.length" class="col-12 col-sm-6 col-lg-4"
    >
      <v-select
        v-model="parroquiasAFiltrar"
        :searchable="false"
        :options="Object.values(parroquias)"
        :getOptionLabel="parroquias => parroquias.sec_zon"
        multiple
        class="outline-datalist my-2"
        @input="$emit('zonasAFiltrar', {estados: estadoSeleccionado, municipios: municipioSeleccionado, parroquias: parroquiasAFiltrar});"
      >
        <template #header>
          <label :class="['datalist-label', {'activo': parroquiasAFiltrar && Object.values(parroquiasAFiltrar).length}]">
            Parroquia(s)
          </label>
        </template>
        <template #option="parroquias">
          {{ parroquias.sec_zon }}
        </template>
      </v-select>
    </div>
    <div v-if="mostrarBtnRestablecer" :class="['col text-center px-0', responsive.btnRestablecer]">
      <mdb-btn
        flat
        dark-waves
        icon="broom"
        class="my-1 px-3"
        @click="$emit('eliminarFiltros'); eliminarFiltrosSeleccionados()"
      >
        Restablecer filtros
      </mdb-btn>
    </div>
  </form>
</template>

<script>
import { mdbBtn, mdbInput } from 'mdbvue'
import { EstatusFactura } from '@/constantes/pagos.js'
export default {
  name: 'FiltrosTipo1',
  components: {
    mdbBtn,
    mdbInput
  },
  props: {
    zonas: {
      type: Array,
      default: () => []
    },
    tiposCli: {
      type: Array,
      default: () => []
    },
    ven: {
      type: Array,
      default: () => []
    },
    nombre: {
      type: Boolean,
      default: false
    },
    fecha: {
      type: Boolean,
      default: false
    },
    mes: {
      type: Boolean,
      default: false
    },
    desdeHasta: {
      type: Boolean,
      default: false
    },
    facturas: {
      type: Boolean,
      default: false
    },
    conDePago: {
      type: Array,
      default: () => []
    },
    tipPrecio: {
      type: Array,
      default: () => []
    },
    restablecerFiltrado: {
      type: Boolean,
      default: false,
      required: false
    },
    mostrarBtnRestablecer: {
      type: Boolean,
      default: true,
      required: false
    },
    responsive: {
      type: Object,
      default: function () {
        return {
          vendedor: '',
          mes: '',
          desde: '',
          hasta: '',
          btnRestablecer: ''
        }
      }
    }
  },
  data () {
    return {
      vendedorAFiltrar: [],
      tiposAFiltrar: [],
      nombreAFiltrar: '',
      fechaAFiltrar: '',
      fechaMes: '',
      fechaDel: '',
      fechaHasta: '',
      estadosAFiltrar: [],
      conPagoFiltrar: [],
      tPreFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: [],
      municipioSeleccionado: [],
      municipios: {},
      parroquias: {},
      vendedor: [],
      tiposCliente: [],
      conPago: [],
      tPre: [],
      EstatusFactura,
      factura: [],
      facturaFiltrar: []
    }
  },
  mounted () {
    this.fechaMes = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
  },
  methods: {
    seleccionarMunicipios (estado) {
      this.estadosAFiltrar = []
      this.estadosAFiltrar.push(estado)
      this.municipioSeleccionado = []
      this.estadoSeleccionado.push(estado)
      this.municipios = estado.municipios
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.$emit('zonasAFiltrar', { estados: this.estadosAFiltrar, municipios: [], parroquias: [] })
    },
    seleccionarParroquias (municipio) {
      this.municipiosAFiltrar = []
      this.municipiosAFiltrar.push(municipio)
      this.municipioSeleccionado.push(municipio)
      this.parroquias = municipio.parroquias
      this.parroquiasAFiltrar = []
      this.$emit('zonasAFiltrar', { estados: this.estadosAFiltrar, municipios: this.municipiosAFiltrar, parroquias: [] })
    },
    vendedoresEnvio () {
      this.vendedorAFiltrar = []
      this.vendedor.forEach(v => {
        this.vendedorAFiltrar.push(v.id_per_ven)
      })
      this.$emit('vendedorAFiltrar', this.vendedorAFiltrar)
    },
    tiposCliEnvio () {
      this.tiposAFiltrar = []
      this.tiposCliente.forEach(t => {
        this.tiposAFiltrar.push(t.id_tcl)
      })
      this.$emit('tiposClienteAFiltrar', this.tiposAFiltrar)
    },
    conPagoEnvio () {
      this.conPagoFiltrar = []
      this.conPago.forEach(cp => {
        this.conPagoFiltrar.push(cp.id_cp)
      })
      this.$emit('condiPagoAFiltrar', this.conPagoFiltrar)
    },
    tPreEnvio () {
      this.tPreFiltrar = []
      this.tPre.forEach(tpre => {
        this.tPreFiltrar.push(tpre.id_tpre)
      })
      this.$emit('tipoPrecioAFiltrar', this.tPreFiltrar)
    },
    facturaEnvio () {
      this.facturaFiltrar = []
      this.factura.forEach(factura => {
        this.facturaFiltrar.push(factura.nombre)
      })
      this.$emit('estatusFacturaAFiltrar', this.facturaFiltrar)
    },
    eliminarFiltrosSeleccionados () {
      this.vendedorAFiltrar = []
      this.tiposAFiltrar = []
      this.nombreAFiltrar = ''
      this.fechaAFiltrar = ''
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.estadoSeleccionado = []
      this.municipioSeleccionado = []
      this.municipios = {}
      this.vendedor = []
      this.parroquias = {}
      this.tiposCliente = []
      this.conPago = []
      this.tPre = []
      this.factura = []
      this.facturaFiltrar = []
      this.fechaDel = ''
      this.fechaHasta = ''
    }
  },
  watch: {
    restablecerFiltrado: function (valor) {
      if (valor) {
        this.eliminarFiltrosSeleccionados()
      }
    }
  },
  computed: {
    estados () {
      const estados = {}
      this.zonas.forEach(e => {
        if (e.eli_zon === '1' || !e.eli_zon) {
          if (!estados[e.est_zon]) {
            estados[e.est_zon] = {
              est_zon: e.est_zon,
              municipios: []
            }
          }
          if (!estados[e.est_zon].municipios[e.ciu_zon]) {
            estados[e.est_zon].municipios[e.ciu_zon] = {
              ciu_zon: e.ciu_zon,
              parroquias: []
            }
          }
          if (!estados[e.est_zon].municipios[e.ciu_zon].parroquias[e.sec_zon]) {
            estados[e.est_zon].municipios[e.ciu_zon].parroquias[e.sec_zon] = {
              sec_zon: e.sec_zon
            }
          }
        }
      })
      return estados
    },
    tipos () {
      const tipos = {}
      this.tiposCli.forEach(e => {
        if (!tipos[e.id_tcl]) {
          tipos[e.id_tcl] = {
            id_tcl: e.id_tcl,
            nom_tcl: e.nom_tcl
          }
        }
      })
      return tipos
    },
    vendedores () {
      const vendedores = {}
      this.ven.forEach(e => {
        if (!vendedores[e.id_ven]) {
          vendedores[e.id_ven] = {
            id_ven: e.id_ven,
            nom_per: e.nom_per,
            ape_per: e.ape_per,
            id_per_ven: e.id_per
          }
        }
      })
      return vendedores
    },
    nombresDeTiposAFiltrar () {
      const nombres = []
      this.tiposAFiltrar.forEach(tipo => {
        nombres.push(this.tipos[tipo].nom_tcl)
      })
      return nombres
    }
    // nombresDeVendedoresAFiltrar () {
    //   const nombres = []
    //   const ListaDeVendedores = Object.values(this.vendedores).map(vendedor => {
    //     return Object.values(vendedor)
    //   })

    //   // Filtra el vendedor para guardar nombre y apellido
    //   this.vendedorAFiltrar.forEach(vendedor => {
    //     ListaDeVendedores.filter(opcionLista => {
    //       if (opcionLista[3] === vendedor) {
    //         nombres.push(`${opcionLista[1]} ${opcionLista[2]}`)
    //       }
    //     })
    //   })
    //   return nombres
    // }
  }
}
</script>

<style lang="scss" scoped>
.row-flex {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;

  p {
    flex-grow: 2;
    margin-bottom: 0;
  }
}
</style>
